import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useSession, SessionProvider } from "next-auth/react";
import { NextComponentType } from "next";
import { AuthUsers } from "../lib";
import Loader from "../components/Loader";

type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean }; // add auth type
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  return (
    <SessionProvider session={session}>
      {Component.auth ? (
        <Auth>
          <Component {...pageProps} />
        </Auth>
      ) : (
        <>
          <Component {...pageProps} />
        </>
      )}
    </SessionProvider>
  );
}

export default MyApp;

const Auth = ({ children }: any) => {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { data: session, status } = useSession({ required: true });

  if (status === "loading") {
    return <Loader text={"Checking authentication..."} />;
  }

  if (status === "authenticated" && AuthUsers.includes(session?.user?.email!)) {
    return children;
  } else {
    return <div>You are not authorized to view this page</div>;
  }
};
