export const BRAND_NAME: string = "African Women Amplified";

export const BRAND_TAGLINE: string =
  "West Africa’s community for ambitious, growth-focused African women entrepreneurs.";

export const BRAND_DESCRIPTION: string =
  "West Africa’s community for ambitious, growth-focused African women entrepreneurs.";

export const BRAND_URL: string = "https://www.africanwomenamplified.com";

export const BRAND_BUTTON_TEXT_ACTION: string = "Be a part of our community";

export const BRAND_BUTTON_TEXT_ABOUT: string = "About Us";

export const BRAND_IMAGE: string =
  "/android-chrome-192x192.png";

export const BRAND_FAVICON: string =
  "/android-chrome-192x192.png";

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};

export const AuthUsers = [
  "julesn.thompson@gmail.com",
  "bachiampong@gmail.com",
  "akosua.koranteng@gmail.com",
  "annang_ar@berkeley.edu",
  "cassidyblay@gmail.com",
];
