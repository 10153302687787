import { NextPage } from "next";

const Loader = ({text}: any) => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-black opacity-90 flex flex-col items-center justify-center">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 className="text-center text-white text-xl font-semibold">{text}</h2>
        <p className="w-1/3 text-center text-white">
          If this takes more than a few seconds, check your connection and try
          again.
        </p>
      </div>
    </>
  );
};

export default Loader;
